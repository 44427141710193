import React from 'react';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';
import Menu from './Menu';
import Hamburger from './Hamburger';
import logo from '../images/logo-light.svg';
import logoMobile from '../images/logo-light.svg';
import MenuMobile from './MenuMobile';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuActive: false,
    };
  }

  toggleMenu = (menuActive) => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive,
    }));
  };

  render() {
    return (
      <Headroom>
        <header className="header" id="top">
          <div className="container">
            <div className="logo">
              <Link to="/">
                <img alt="WXT MEDIA" src={logo} />
              </Link>
            </div>
            <div className="logo-mobile">
              <Link to="/">
                <img alt="WXT MEDIA" src={logoMobile} />
              </Link>
            </div>
            <MenuMobile active={this.state.menuActive} />
            <Menu />
            <Hamburger toggleMenu={this.toggleMenu} />
          </div>
        </header>
      </Headroom>
    );
  }
}

export default Header;
