import * as React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby';
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

const Footer = props => (
  <footer className="footer-strip">
    <div className="container">
      <div className="row footer">
        <div className="col-12 col-md-5 text-center text-md-left">
          <span className="copyright">
            ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            <a href="https://wxt.media">WXT MEDIA // Design &amp; Entwicklung aus Hamburg</a>
          </span>
        </div>
        <div className="col-12 col-md-3 text-center">
          <ThemeToggler>
            {({ theme, toggleTheme }) => {
              // Don't render anything at compile time. Deferring rendering until we
              // know which theme to use on the client avoids incorrect initial
              // state being displayed.
              if (theme == null) {
                return null
              }
              return (
                <div className="dark-button">
                  <input
                    type="checkbox"
                    id="toggle"
                    onChange={e =>
                      toggleTheme(e.target.checked ? "dark" : "light")
                    }
                    checked={theme === "dark"}
                  />
                  <label htmlFor="toggle"></label>
                </div>
              )
            }}
          </ThemeToggler>
        </div>

        <div className="col-12 text-center text-md-right col-md-4">
          <nav>
            <ul className="footer-menu">
              <li>
                {' '}
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                {' '}
                <Link to="/imprint">Imprint</Link>
              </li>
              <li>
                {' '}
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </footer>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
