import React from 'react';
import Helmet from 'react-helmet';
import { withPrefix, Link } from 'gatsby';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SubFooter from '../components/SubFooter';
import '../scss/style.scss';
import '/static/font-awesome/css/font-awesome.min.css';


if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

const Layout = props => (
  <React.Fragment>
    <SEO />
    <Header />
    <section className={`page${props.bodyClass ? ` ${props.bodyClass}` : ''}`}>
      <div id="wrapper" className="wrapper">
        <div className="strip strip-white strip-diagonal">
          <div className="bubbleHolder">
            <div className="bubbleHolder2">
              <div className="contentHolder">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <SubFooter />
  </React.Fragment>
);

export default Layout;
