import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({
  description, lang, image, meta, keywords, title, pathname,
}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const siteTitle = title || data.site.siteMetadata.title;
        const metaDescription = description || data.site.siteMetadata.description;
        const metaImage = image && image.src ? `${data.site.siteMetadata.siteUrl}${image.src}` : null;
        const metaUrl = `${data.site.siteMetadata.siteUrl}${pathname}`;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={siteTitle}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: siteTitle,
              },
              {
                property: 'og:url',
                content: metaUrl,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:creator',
                content: `@${data.site.siteMetadata.social.twitter}`,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                name: 'google-site-verification',
                content: 'xB58nbMBXY4i4Mx91pEiXbjwYKxwTxAxdgezjmF4lrM',
              },
            ]
              .concat(
                metaImage
                  ? [
                    {
                      property: 'og:image',
                      content: metaImage,
                    },
                    {
                      property: 'og:image:alt',
                      content: siteTitle,
                    },
                    {
                      property: 'og:image:width',
                      content: image.width,
                    },
                    {
                      property: 'og:image:height',
                      content: image.height,
                    },
                    {
                      name: 'twitter:card',
                      content: 'summary_large_image',
                    },
                  ]
                  : [
                    {
                      name: 'twitter:card',
                      content: 'summary',
                    },
                  ],
              )
              .concat(
                keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', '),
                  }
                  : [],
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  pathname: '',
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.object,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        author
        social {
          twitter
        }
      }
    }
  }
`;
