import React from 'react';

import { Link, graphql, StaticQuery } from 'gatsby';
import { Location } from '@reach/router';

const SubFooter = props => (
  <div className="sub-footer-strip">
    <div className="sub-footer">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            Made on a Mac with
{' '}
<i className="fa fa-heart" />
. Powered by
{' '}
            <a href="https://www.gatsbyjs.org/" target="_blank">
              Gatsby
            </a>
            .
            <span className="top">
              {' '}
              <Location>
                {({ location }) => (
                  <Link to={`${location.pathname}#top`}>
                    <i className="fal fa-arrow-up" />
                  </Link>
                )}
              </Location>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={data => <SubFooter data={data} />}
  />
);
